.FooterWrapper {
    min-height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 30px;
    background-color: #154554;
}

.FooterLabel {
    text-align: center;
    font-size: 2vh;
    font-weight: 400;
    font-family: 'Montserrat';
    margin: 0;
    color: white;
    cursor: pointer;
}

.FooterHref {
    all: unset;
}