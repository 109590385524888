.ContactUsWrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.ContactUsLabelWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ContactUsLabel {
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat';
    margin: 0;
}

.ContactUsEmailInput {
    width: 95%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #222;
    padding-left: 10px;
    font-family: 'Montserrat';
    font-size: .9rem;
    font-weight: 400;
}

.ContactUsMessageInput {
    width: 95%;
    max-width: 95%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #222;
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat';
    font-size: .9rem;
    font-weight: 400;
}