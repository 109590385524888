.ModalContainer {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #555;
    width: 450px;
    border: none;
    max-width: 80vw;
    padding: 30px;
    gap: 30px;
}

.ModalHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ModalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
}

.CloseModal {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: 'Montserrat';
    margin: 0;
    cursor: pointer;
}

.FooterModalWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.SecondaryButton {
    all: unset;
    padding: 8px 20px;
    background: #222;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    cursor: pointer;
    border-radius: 3px;
    font-family: 'Montserrat';
}

.PrimaryButton {
    all: unset;
    padding: 8px 20px;
    background: none;
    color: #222;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
    cursor: pointer;
    border: 1px solid #222;
    border-radius: 3px;
    font-family: 'Montserrat';
}