.ListWrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 60px;
    display: flex;
    flex-direction: column;
}

.CardWrapper {
    width: 70%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 10%;
}

.CardWrapperMobile {
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 10%;
}

.CardTextWrapperMobile {
    display: flex;
    flex-direction: column;
    flex: 1.5;
    justify-content: center;
}

.CardTextWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: left;
}

.CardImageWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.CardImageBackground {
    position: absolute;
    bottom: 40px;
    z-index: -1;
    background-color: #154554;
    height: 11vw;
    width: 25vw;
    border-radius: 20px;
}

.RoadMapTitle {
    text-align: center;
    font-size: 5.5vh;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
}

.CardTitle {
    font-size: 3vh;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
}

.CardSubTitle {
    font-size: 2vh;
    font-weight: 400;
    font-family: 'Montserrat';
}