.BadgeWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.BadgePageWrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 60px;
    display: flex;
    flex-direction: column;
}

.BadgeTitle {
    text-align: center;
    font-size: 5.5vh;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
}

.BadgeLeft {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding-right: 20px;
    align-self: center;
    align-items: center;
}

.BadgeRight {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 20px;
    align-items: center;
    align-self: center;
}