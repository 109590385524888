.HeaderWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    overflow: visible;
}

.HeaderBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 65vh;
    background-color: #154554;
    z-index: -1;
}

.TitleWrapper {
    width: 100%;
    padding-top: 60px;
}

.Title {
    text-align: center;
    color: white;
    font-size: 5.5vh;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
}

.SubTitle {
    text-align: center;
    color: white;
    font-size: 2vh;
    font-weight: 400;
    font-family: 'Montserrat';
}

.ImageContainer {
    justify-content: center;
    align-items: center;
    display: flex;
}